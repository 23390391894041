import React, { useEffect, useMemo, useState } from 'react';
import {
	Autocomplete,
	Button,
	Checkbox,
	Chip,
	CircularProgress,
	Divider,
	IconButton,
	InputAdornment,
	ListItemButton,
	Slide,
	TextField,
	Typography,
	Zoom,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
	CheckBox,
	CheckBoxOutlineBlank,
	ChevronRightRounded,
	CloseRounded,
	CloudDownload,
	KeyboardBackspace,
	Save,
} from '@mui/icons-material/';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Column, Line } from '../../../styles';
import { usePO } from '../../../utils/POContext';
import FormDivider from '../../../components/FormDivider';
import {
	fetchAvailablePlatforms,
	fetchDenouncementSources,
	fetchDenouncementsSearchByAPI,
	fetchDenouncementStatus,
	fetchFields,
	fetchUsers,
} from '../../../services/denouncements/denouncementsSearch';
import { useSnackbar } from 'notistack';
import LoadingDots from '../../../components/LoadingDots';
import { OfferToolTip } from '../../../helpers';
import TagSelector from '../../../components/TagSelector';
import RadialMenu from '../../../components/RadialMenu';
import { getDenouncementSpreadSheet } from '../../../services/denouncements/denouncementsSearch';
import {
	removeSpecificFilter,
	resetDenouncementsFilters,
	updateDenouncementsField,
	updateDenouncementsFilters,
} from '../../../redux/slices/filterSlice';
export interface viewAllowedItemsProps {
	viewAllowedItems: any;
	hadleSetViewAllowedItems: () => void;
}

interface Props {
	buildTableData: (data: any, totalHits?: number) => void;
	currentPage: number;
	isFilterBarOpen: boolean;
	loading: boolean;
	refreshTable: number;
	rowsPerPage: number;
	searchText: string;
	setIsFilterBarOpen: (value: boolean) => void;
	setLoading: (value: boolean) => void;
}

const DenouncementsFilterBar: React.FC<Props> = ({
	buildTableData,
	currentPage,
	isFilterBarOpen,
	loading,
	refreshTable,
	rowsPerPage,
	searchText,
	setIsFilterBarOpen,
	setLoading,
}) => {
	const { t } = useTranslation();
	const { selectedTheme, selectedClient, tagsActiveClient, updateCursorMode } =
		usePO();
	const { enqueueSnackbar } = useSnackbar();
	const checkedIcon = <CheckBox fontSize="small" />;
	const drawerWidth = window.innerHeight < 901 ? 60 : 70;
	const drawerWidthOpen = window.innerHeight < 901 ? 200 : 230;
	const icon = <CheckBoxOutlineBlank fontSize="small" />;
	const vertMenuState = useSelector((state: any) => state.vertMenu);

	// Name of the redux filter to be saved
	const [nameFilter, setNameFilter] = useState('');

	const filtersRedux = useSelector((state: any) => state.filters);

	const [selectedFilter, setSelectedFilter] = useState('defaultFilters');

	const dispatch = useDispatch();

	let denouncementTypeIDToSearch: string[] | null =
		filtersRedux.denouncements[selectedFilter].denouncementTypeIDToSearch;
	let platformINcountryIDToSearch: string | null =
		filtersRedux.denouncements[selectedFilter].platformINcountryIDToSearch;
	let selectedFieldToOrderBy: string | null =
		filtersRedux.denouncements[selectedFilter].selectedFieldToOrderBy;
	let selectedOrdination: any =
		filtersRedux.denouncements[selectedFilter].selectedOrdination;
	let sourceIDToSearch: string | null =
		filtersRedux.denouncements[selectedFilter].sourceIDToSearch;

	const orderOptions = [
		{ name: 'Ascend.', id: 'ASC' },
		{ name: 'Descend.', id: 'DESC' },
	];

	const [availableFields, setAvailableFields] = useState<string[]>([]);
	const [availablePlatforms, setAvailablePlatforms] = useState<any[]>([]);

	const [inputValue, setInputValue] = useState('');
	const [availableUserIDs, setAvailableUserIDs] = useState<any[]>([]);
	const [selectedUsers, setSelectedUsers] = useState<any[]>(
		filtersRedux.denouncements[selectedFilter].selectedUsers || []
	);

	const [denouncementSources, setDenouncementSources] = useState<any[]>([]);
	const [denouncementStatus, setDenouncementStatus] = useState<any[]>([]);
	const [denounceStatusIDs, setDenounceStatusIDs] = useState<string[]>(
		filtersRedux.denouncements[selectedFilter].denounceStatusIDs
	);
	const [loadingDenouncementStatus, setLoadingDenouncementStatus] =
		useState<boolean>(false);
	const [loadingFields, setLoadingFields] = useState<boolean>(false);
	const [loadingPlatforms, setLoadingPlatforms] = useState<boolean>(false);
	const [loadingSources, setLoadingSources] = useState<boolean>(false);
	const [loadingUserIDs, setLoadingUserIDs] = useState<boolean>(false);
	const [mouseOver, setMouseOver] = useState(false);
	const [selectedCountry, setSelectedCountry] = useState<any>(
		filtersRedux.denouncements[selectedFilter].selectedCountry
	);
	const [selectedFinalDate, setSelectedFinalDate] = useState<any>(
		filtersRedux.denouncements[selectedFilter].selectedFinalDate
	);
	const [selectedPlatform, setSelectedPlatform] = useState<any>(
		filtersRedux.denouncements[selectedFilter].selectedPlatform
	);
	const [selectedSource, setSelectedSource] = useState<any>(
		filtersRedux.denouncements[selectedFilter].selectedSource
	);
	const [selectedStartDate, setSelectedStartDate] = useState<any>(
		filtersRedux.denouncements[selectedFilter].selectedStartDate
	);
	const [selectedTags, setSelectedTags] = useState<any[]>(
		filtersRedux.denouncements[selectedFilter].selectedTags
	);

	const [titleToSearch, setTitleToSearch] = useState<string | null>(
		filtersRedux.denouncements[selectedFilter].titleToSearch
	);
	const [urlToSearch, setUrlToSearch] = useState<string | null>(
		filtersRedux.denouncements[selectedFilter].urlToSearch
	);

	const getUsers = async () => {
		setLoadingUserIDs(true);
		const response: any = await fetchUsers(selectedClient.id);
		if (response?.success) {
			const tempData: any[] = [];
			const seenUserIDs = new Set();

			response.data.forEach((el) => {
				const userEmail = el.userID.split('|');
				const formattedData = {
					id: el.id,
					userID: el.userID,
					email: userEmail[2],
					ISactive: el.ISactive,
				};
				if (!seenUserIDs.has(formattedData.userID)) {
					tempData.push(formattedData);
					seenUserIDs.add(formattedData.userID);
				}
			});
			setAvailableUserIDs(tempData);
			setLoadingUserIDs(false);
		}
	};

	const getFields = async () => {
		setLoadingFields(true);
		const response: any = await fetchFields();
		if (response?.success) {
			setAvailableFields(response.data);
		}
		setLoadingFields(false);
	};

	const getSources = async () => {
		setLoadingSources(true);
		const response: any = await fetchDenouncementSources();
		if (response?.success) {
			setDenouncementSources(response.data);
		}
		setLoadingSources(false);
	};

	const getDenouncementStatus = async () => {
		setLoadingDenouncementStatus(true);
		const response: any = await fetchDenouncementStatus();
		if (response?.success) {
			setDenouncementStatus(response.data);
		}
		setLoadingDenouncementStatus(false);
	};

	const getAvailablePlatforms = async () => {
		setLoadingPlatforms(true);
		const response: any = await fetchAvailablePlatforms();
		if (response?.success) {
			setAvailablePlatforms(Object.values(response.data));
		}
		setLoadingPlatforms(false);
	};

	const queryParams = () => {
		const filters = filtersRedux.denouncements[selectedFilter];
		return {
			url: filters.urlToSearch,
			title: filters.titleToSearch,
			denounceStatusIDs:
				filters.denounceStatusIDs.length > 0
					? filters.denounceStatusIDs.map((item: any) => item.id)
					: null,
			sourceID: filters.selectedSource?.id,
			clientID: selectedClient.id,
			userID:
				filters.selectedUsers && filters.selectedUsers.length > 0
					? filters.selectedUsers.map((item) => item.userID).join(',')
					: null,
			platformINcountryID: filters.platformINcountryIDToSearch,
			denouncementTypeID: filters.denouncementTypeIDToSearch,
			startDate: filters.selectedStartDate,
			finalDate: filters.selectedFinalDate,
			itemsPerPage: rowsPerPage,
			page: currentPage + 1,
			orderField: filters.selectedFieldToOrderBy ?? 'updated',
			ordination: filters.selectedOrdination ?? 'DESC',
			tags:
				filters.selectedTags.length > 0
					? filters.selectedTags.map((item) => item.tagID)
					: null,
		};
	};

	const sendSearchQuery = async () => {
		setLoading(true);
		const response: any = await fetchDenouncementsSearchByAPI(queryParams());
		if (response?.success) {
			buildTableData(response.data.hits, response.data.totalHits);
		} else {
			enqueueSnackbar(response.message, {
				variant: 'info',
			});
			buildTableData([]);
			setLoading(false);
		}
	};

	useEffect(() => {
		handleClearFilters();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (selectedFilter !== 'defaultFilters') {
			sendSearchQuery();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedFilter]);

	useEffect(() => {
		if (!selectedPlatform) {
			dispatch(
				updateDenouncementsField({
					fieldName: 'platformINcountryIDToSearch',
					value: null,
					filterName: selectedFilter,
				})
			);
			dispatch(
				updateDenouncementsField({
					fieldName: 'selectedCountry',
					value: null,
					filterName: selectedFilter,
				})
			);
			dispatch(
				updateDenouncementsField({
					fieldName: 'selectedPlatform',
					value: null,
					filterName: selectedFilter,
				})
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedPlatform]);

	useEffect(() => {
		if (selectedClient?.id !== undefined) {
			sendSearchQuery();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		selectedClient,
		searchText,
		currentPage,
		refreshTable,
		rowsPerPage,
		filtersRedux,
	]);

	const saveTagsRedux = () => {
		dispatch(
			updateDenouncementsField({
				fieldName: 'selectedTags',
				value: selectedTags,
				filterName: selectedFilter,
			})
		);
	};

	const handleDownload = async () => {
		updateCursorMode('wait');
		enqueueSnackbar(
			t(
				'Classificações.O download iniciará automaticamente. Aguarde por favor.'
			),
			{ variant: 'info' }
		);
		const response: any = await getDenouncementSpreadSheet(queryParams());
		if (response.success === true) {
			enqueueSnackbar(response.message, {
				variant: 'success',
			});
		} else if (response.success === false) {
			enqueueSnackbar(t(response.message), {
				variant: 'error',
			});
		}
		updateCursorMode('default');
	};

	const useStyles = makeStyles(() => ({
		option: {
			backgroundColor:
				selectedTheme.id === 'dark' ? selectedTheme?.overlay3dp : '',
			color: selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
		},
		renderOption: {
			'&.MuiAutocomplete-option': {
				'&:hover': {
					backgroundColor:
						selectedTheme.id === 'dark' && selectedTheme?.foreground,
				},
			},
		},
	}));

	const classes = useStyles();

	const menuItem1Icon = (
		<CloudDownload
			style={{
				fontSize: 22,
				marginTop: -3,
				color: 'white',
			}}
		/>
	);

	const menuData = [
		{
			icon: menuItem1Icon,
			action: handleDownload,
			tooltip: 'Classificações.Baixar planilha com os resultados da busca',
		},
	];

	function setFilterState(selectedFilter, clear?) {
		const filter = filtersRedux.denouncements[selectedFilter];
		setSelectedStartDate(clear ? null : filter.selectedStartDate);
		setSelectedFinalDate(clear ? null : filter.selectedFinalDate);
		setDenounceStatusIDs(clear ? [] : filter.denounceStatusIDs);
		setSelectedPlatform(clear ? null : filter.selectedPlatform);
		setSelectedCountry(clear ? null : filter.selectedCountry);
		setSelectedSource(clear ? null : filter.selectedSource);
		setSelectedUsers(clear ? [] : filter.selectedUsers);
		setTitleToSearch(clear ? '' : filter.titleToSearch);
		setSelectedTags(clear ? [] : filter.selectedTags);
		setUrlToSearch(clear ? '' : filter.urlToSearch);
		platformINcountryIDToSearch = clear
			? null
			: filter.platformINcountryIDToSearch;
		denouncementTypeIDToSearch = clear
			? null
			: filter.denouncementTypeIDToSearch;
		selectedFieldToOrderBy = clear ? null : filter.selectedFieldToOrderBy;
		selectedOrdination = clear ? null : filter.selectedOrdination;
		sourceIDToSearch = clear ? null : filter.sourceIDToSearch;
	}

	const saveDefaultFilters = (nameFilter) => {
		dispatch(
			updateDenouncementsFilters({
				filterName: nameFilter,
				filterData: {
					selectedFieldToOrderBy: selectedFieldToOrderBy,
					selectedOrdination: selectedOrdination,
					titleToSearch: titleToSearch,
					urlToSearch: urlToSearch,
					selectedStartDate: selectedStartDate,
					selectedFinalDate: selectedFinalDate,
					denounceStatusIDs: denounceStatusIDs,
					selectedPlatform: selectedPlatform,
					selectedCountry: selectedCountry,
					selectedSource: selectedSource,
					sourceIDToSearch: sourceIDToSearch,
					platformINcountryIDToSearch: platformINcountryIDToSearch,
					denouncementTypeIDToSearch: denouncementTypeIDToSearch,
					selectedUsers: selectedUsers,
					selectedTags: selectedTags,
				},
			})
		);
	};

	const handleClearFilters = () => {
		dispatch(resetDenouncementsFilters());
		setSelectedFilter('defaultFilters');
		setFilterState('defaultFilters', true);
	};

	const handleCloseOnBlur = () => {
		if (!mouseOver) {
			setIsFilterBarOpen(false);
		}
	};

	const handleSelectSource = (event: any, value: any) => {
		setSelectedSource(value);
		sourceIDToSearch = value;
		dispatch(
			updateDenouncementsField({
				fieldName: 'selectedSource',
				value: value,
				filterName: selectedFilter,
			})
		);
	};

	const handleSelectPlatform = (value: any) => {
		if (value === null) {
			dispatch(
				updateDenouncementsField({
					fieldName: 'selectedPlatform',
					value: value,
					filterName: selectedFilter,
				})
			);
		}
		setSelectedPlatform(value);
		setSelectedCountry(null);
	};

	const handleSelectPlatformINcountryID = (country: any) => {
		if (country.id !== undefined) {
			platformINcountryIDToSearch = `${selectedPlatform.name}|${country.id}`;
			dispatch(
				updateDenouncementsField({
					fieldName: 'platformINcountryIDToSearch',
					value: platformINcountryIDToSearch,
					filterName: selectedFilter,
				})
			);
			dispatch(
				updateDenouncementsField({
					fieldName: 'selectedCountry',
					value: country,
					filterName: selectedFilter,
				})
			);
			setSelectedCountry(country);
			// sendSearchQuery();
		}
	};

	const handleChangeStartDate = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setSelectedStartDate(event.target.value + 'T00:00');
	};

	const handleChangeFinalDate = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setSelectedFinalDate(event.target.value + 'T23:59');
	};

	const handleChangeSelectedUsers = (_, newValue) => {
		setSelectedUsers(newValue);
	};

	const renderFilterBarHeader = () => (
		<Line
			style={{
				width: '100%',
				justifyContent: 'space-between',
				marginRight: -4,
			}}
		>
			<Typography
				variant="subtitle2"
				style={{
					fontWeight: 'bold',
					color:
						selectedTheme.id === 'dark'
							? selectedTheme.textColorHigh
							: '#4F4F4F',
				}}
			>
				{t('FilterBar.Filtros avançados')}:
			</Typography>
			<IconButton
				onClick={() => setIsFilterBarOpen(false)}
				data-testid="close-filter-bar-button"
				size="small"
				style={{ marginRight: -5 }}
				sx={{
					'&:hover': {
						backgroundColor:
							selectedTheme.id === 'dark' && selectedTheme.primaryLight,
					},
				}}
			>
				<KeyboardBackspace
					style={{
						color:
							selectedTheme.id === 'dark'
								? selectedTheme.textColorHigh
								: '#4F4F4F',
					}}
				/>
			</IconButton>
		</Line>
	);

	const renderPublicationFilters = () => (
		<>
			<FormDivider
				name={t('FilterBar.Publicação')}
				margin="0 0 6px 0"
				opacity={0.8}
				background={selectedTheme.id === 'dark' && selectedTheme.overlay8dp}
			/>
			<TextField
				data-testid="titleToSearch"
				variant="outlined"
				size="small"
				value={titleToSearch || ''}
				placeholder={t('FilterBar.Título')}
				onChange={(event) => {
					setTitleToSearch(event.target.value);
				}}
				onKeyDown={(event) => {
					if (event.keyCode === 13) {
						dispatch(
							updateDenouncementsField({
								fieldName: 'titleToSearch',
								value: titleToSearch,
								filterName: selectedFilter,
							})
						);
					}
				}}
				sx={{
					width: '100%',
					height: '40px',
					borderRadius: '4px',
					background:
						selectedTheme.id === 'dark'
							? selectedTheme.overlay3dp
							: selectedTheme.foreground,
					boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
					'& .MuiInputBase-root': {
						'& > fieldset': {
							borderColor: selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
						},
						':hover': {
							'& > fieldset': {
								borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
							},
						},
					},
				}}
				InputProps={{
					style: {
						color:
							selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
					},
					endAdornment: (
						<InputAdornment position="end" style={{ marginRight: -12 }}>
							<Divider
								style={{
									height: 28,
									background:
										selectedTheme.id === 'dark' ? selectedTheme.footerLine : '',
								}}
								orientation="vertical"
							/>
							<IconButton
								size="small"
								onClick={sendSearchQuery}
								style={{
									padding: 7,
									borderRadius: '0px 5px 5px 0px ',
								}}
								sx={{
									'&:hover': {
										backgroundColor:
											selectedTheme.id === 'dark' && selectedTheme.primaryLight,
									},
								}}
							>
								<ChevronRightRounded
									style={{
										color:
											selectedTheme.id === 'dark'
												? selectedTheme.textColorHigh
												: '',
									}}
								/>
							</IconButton>
						</InputAdornment>
					),
				}}
			/>
			<TextField
				variant="outlined"
				data-testid="urlToSearch"
				value={urlToSearch}
				size="small"
				placeholder={t('FilterBar.URL da publicação')}
				onChange={(event) => {
					setUrlToSearch(event.target.value);
				}}
				onKeyDown={(event) => {
					if (event.keyCode === 13) {
						dispatch(
							updateDenouncementsField({
								fieldName: 'urlToSearch',
								value: urlToSearch,
								filterName: selectedFilter,
							})
						);
						// sendSearchQuery();
					}
				}}
				sx={{
					width: '100%',
					height: '40px',
					borderRadius: '4px',
					background:
						selectedTheme.id === 'dark'
							? selectedTheme.overlay3dp
							: selectedTheme.foreground,
					boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
					'& .MuiInputBase-root': {
						'& > fieldset': {
							borderColor: selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
						},
						':hover': {
							'& > fieldset': {
								borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
							},
						},
					},
				}}
				InputProps={{
					style: {
						color:
							selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
					},
					endAdornment: (
						<InputAdornment position="end" style={{ marginRight: -12 }}>
							<Divider
								style={{
									height: 28,
									background:
										selectedTheme.id === 'dark' ? selectedTheme.footerLine : '',
								}}
								orientation="vertical"
							/>
							<IconButton
								size="small"
								onClick={sendSearchQuery}
								style={{
									padding: 7,
									borderRadius: '0px 5px 5px 0px ',
								}}
								sx={{
									'&:hover': {
										backgroundColor:
											selectedTheme.id === 'dark' && selectedTheme.primaryLight,
									},
								}}
							>
								<ChevronRightRounded
									style={{
										color:
											selectedTheme.id === 'dark'
												? selectedTheme.textColorHigh
												: '',
									}}
								/>
							</IconButton>
						</InputAdornment>
					),
				}}
			/>
			<div
				style={{
					width: '100%',
					boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
					borderRadius: 4,
				}}
			>
				<TagSelector
					dataArray={selectedTags}
					setDataArray={setSelectedTags}
					suggestions={tagsActiveClient}
					handleSaveTags={saveTagsRedux}
					limitTags={1}
					size="small"
					tagSize="small"
					autoSave
				/>
			</div>
		</>
	);

	const renderDenouncementFilters = () => (
		<>
			<FormDivider
				name={t('FilterBar.Denúncia')}
				margin="10px 0 7px 0"
				opacity={0.8}
				background={selectedTheme.id === 'dark' && selectedTheme.overlay8dp}
			/>
			<Autocomplete
				id="denouncement-sources"
				style={{
					width: 310,
					background: selectedTheme.foreground,
					borderRadius: 4,
					borderColor: 'transparent',
				}}
				classes={{
					paper: classes.option,
				}}
				// open={open}
				onOpen={async () => {
					if (denouncementSources.length === 0) {
						await getSources();
					}
				}}
				onChange={handleSelectSource}
				isOptionEqualToValue={(option: any, value) => {
					sourceIDToSearch = value?.id;
					return option.name === value.name;
				}}
				getOptionLabel={(option) => option.name}
				size="small"
				value={selectedSource}
				options={denouncementSources}
				loading={loadingSources}
				data-testid="denouncement-sources"
				renderInput={(params) => (
					<TextField
						{...params}
						placeholder={
							denouncementSources.length === 0 || sourceIDToSearch === null
								? t('FilterBar.Todas as fontes')
								: t('FilterBar.Fonte')
						}
						variant="outlined"
						sx={{
							'& .MuiInputBase-input': {
								color:
									selectedTheme.id === 'dark' && selectedTheme?.textColorHigh,
							},
							'& .MuiInputBase-input::placeholder': {
								color:
									selectedTheme.id === 'dark' && selectedTheme?.textColorHigh,
							},
							'& .MuiInputBase-root': {
								'& > fieldset': {
									borderColor:
										selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
								},
								':hover': {
									'& > fieldset': {
										borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
									},
								},
							},
							'.MuiSvgIcon-root ': {
								fill:
									selectedTheme.id === 'dark' && selectedTheme?.textColorMedium,
							},
							background:
								selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
							boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
						}}
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<React.Fragment>
									{loadingSources ? (
										<CircularProgress color="inherit" size={20} />
									) : null}
									{params.InputProps.endAdornment}
								</React.Fragment>
							),
						}}
					/>
				)}
			/>
			<Autocomplete
				data-testid="denouncement-status"
				style={{
					width: 310,
					background: selectedTheme.foreground,
					borderRadius: 4,
					borderColor: 'transparent',
				}}
				classes={{
					paper: classes.option,
				}}
				onOpen={async () => {
					if (denouncementStatus.length === 0) {
						await getDenouncementStatus();
					}
				}}
				onClose={() => {
					dispatch(
						updateDenouncementsField({
							fieldName: 'denounceStatusIDs',
							value: denounceStatusIDs,
							filterName: selectedFilter,
						})
					);
				}}
				onChange={(_, newValue) => {
					if (newValue.length === 0) {
						dispatch(
							updateDenouncementsField({
								fieldName: 'denounceStatusIDs',
								value: newValue,
								filterName: selectedFilter,
							})
						);
						// sendSearchQuery();
					}
					setDenounceStatusIDs(newValue);
				}}
				renderTags={(value) =>
					value.map((option) => (
						<Chip
							size="small"
							label={option?.id}
							key={option.id}
							sx={{
								marginInline: '3px',
								color:
									selectedTheme.id === 'dark' && selectedTheme?.textColorHigh,
								border:
									selectedTheme.id === 'dark' ? '1px solid #575757' : 'none',
								backgroundColor: selectedTheme.primaryDark,
							}}
						/>
					))
				}
				value={denounceStatusIDs}
				multiple
				disableCloseOnSelect
				limitTags={1}
				isOptionEqualToValue={(option: any, value) => {
					return option.id === value.id;
				}}
				getOptionLabel={(option) => option.id}
				size="small"
				options={denouncementStatus}
				loading={loadingDenouncementStatus}
				renderOption={(props, option, { selected }) => (
					<li {...props}>
						<Checkbox
							icon={icon}
							size="small"
							checkedIcon={checkedIcon}
							sx={{
								marginRight: '8px',
								color:
									selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
								'&.Mui-checked': {
									color:
										selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
								},

								'&:hover': {
									backgroundColor:
										selectedTheme.id === 'dark' && selectedTheme.primaryLight,
								},
							}}
							checked={selected}
						/>
						{option.id}
					</li>
				)}
				renderInput={(params) => (
					<TextField
						{...params}
						placeholder={
							denounceStatusIDs.length === 0
								? t('FilterBar.Todos os status')
								: undefined
						}
						variant="outlined"
						sx={{
							'& .MuiInputBase-input': {
								color:
									selectedTheme.id === 'dark' && selectedTheme?.textColorHigh,
							},
							'& .MuiInputBase-input::placeholder': {
								color:
									selectedTheme.id === 'dark' && selectedTheme?.textColorHigh,
							},
							'& .MuiInputBase-root': {
								'& > fieldset': {
									borderColor:
										selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
								},
								':hover': {
									'& > fieldset': {
										borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
									},
								},
							},
							'.MuiSvgIcon-root ': {
								fill:
									selectedTheme.id === 'dark' && selectedTheme?.textColorMedium,
							},
							background:
								selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,

							boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
						}}
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<React.Fragment>
									{loadingDenouncementStatus ? (
										<CircularProgress color="inherit" size={20} />
									) : null}
									{params.InputProps.endAdornment}
								</React.Fragment>
							),
						}}
					/>
				)}
			/>
		</>
	);

	const renderPlatformFilters = () => (
		<>
			<FormDivider
				name={t('FilterBar.Plataforma')}
				margin="10px 0 7px 0"
				opacity={0.8}
				background={selectedTheme.id === 'dark' && selectedTheme.overlay8dp}
			/>
			<Autocomplete
				id="platform-autocomplete"
				data-testid="platform-autocomplete"
				style={{
					width: 310,
					background: 'white',
					borderRadius: 4,
					borderColor: 'transparent',
				}}
				classes={{
					paper: classes.option,
				}}
				onOpen={async () => {
					if (availablePlatforms.length === 0) {
						await getAvailablePlatforms();
					}
				}}
				value={selectedPlatform}
				onChange={(_, value) => {
					handleSelectPlatform(value);
				}}
				isOptionEqualToValue={(option: any, value) => {
					return option.name === value.name;
				}}
				getOptionLabel={(option) => option.name}
				size="small"
				options={availablePlatforms}
				loading={loadingPlatforms}
				renderInput={(params) => (
					<TextField
						{...params}
						placeholder={
							availablePlatforms.length === 0 ||
							platformINcountryIDToSearch === null
								? t('FilterBar.Todas as plataformas')
								: t('FilterBar.Plataforma')
						}
						variant="outlined"
						sx={{
							'& .MuiInputBase-input': {
								color:
									selectedTheme.id === 'dark' && selectedTheme?.textColorHigh,
							},
							'& .MuiInputBase-input::placeholder': {
								color:
									selectedTheme.id === 'dark' && selectedTheme?.textColorHigh,
							},
							'& .MuiInputBase-root': {
								'& > fieldset': {
									borderColor:
										selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
								},
								':hover': {
									'& > fieldset': {
										borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
									},
								},
							},
							'.MuiSvgIcon-root ': {
								fill:
									selectedTheme.id === 'dark' && selectedTheme?.textColorMedium,
							},
							background:
								selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
							boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
						}}
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<React.Fragment>
									{loadingPlatforms ? (
										<CircularProgress color="inherit" size={20} />
									) : null}
									{params.InputProps.endAdornment}
								</React.Fragment>
							),
						}}
					/>
				)}
			/>
			{selectedPlatform?.countries?.length > 0 ? (
				<Autocomplete
					id="country"
					data-testid="country-autocomplete"
					style={{
						width: 310,
						background: selectedTheme.foreground,
						borderRadius: 4,
						borderColor: 'transparent',
					}}
					isOptionEqualToValue={(option: any, value) => {
						return option.name === value.name;
					}}
					value={selectedCountry}
					disableClearable
					onChange={(_, value: any) => {
						handleSelectPlatformINcountryID(value);
					}}
					getOptionLabel={(option) => option.name}
					size="small"
					options={selectedPlatform?.countries}
					loading={loadingPlatforms}
					classes={{
						paper: classes.option,
					}}
					renderInput={(params) => (
						<TextField
							{...params}
							error={
								selectedPlatform?.countries?.length > 0 &&
								selectedCountry === null
							}
							inputProps={{
								...params.inputProps,
								autoComplete: 'new-password', // disable autocomplete and autofill
								style: {
									color:
										selectedTheme.id === 'dark'
											? selectedTheme?.textColorHigh
											: '',
								},
							}}
							placeholder={t('FilterBar.Selecione um país')}
							variant="outlined"
							sx={{
								'& .MuiInputBase-input': {
									color:
										selectedTheme.id === 'dark' && selectedTheme?.textColorHigh,
								},
								'& .MuiInputBase-input::placeholder': {
									color:
										selectedTheme.id === 'dark' && selectedTheme?.textColorHigh,
								},
								'& .MuiInputBase-root': {
									'& > fieldset': {
										borderColor:
											selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
									},
									':hover': {
										'& > fieldset': {
											borderColor:
												selectedTheme.id === 'dark' ? '#fff' : '#000',
										},
									},
								},
								'.MuiSvgIcon-root ': {
									fill:
										selectedTheme.id === 'dark' &&
										selectedTheme?.textColorMedium,
								},
								background:
									selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
								boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
							}}
							InputProps={{
								...params.InputProps,
								endAdornment: (
									<React.Fragment>
										{loadingPlatforms ? (
											<CircularProgress color="inherit" size={20} />
										) : null}
										{params.InputProps.endAdornment}
									</React.Fragment>
								),
							}}
						/>
					)}
				/>
			) : null}
		</>
	);

	const renderResultsFilters = () => (
		<>
			<FormDivider
				name={t('FilterBar.Ordenar resultados')}
				margin="10px 0 7px 0"
				opacity={0.8}
				background={selectedTheme.id === 'dark' && selectedTheme.overlay8dp}
			/>
			<Autocomplete
				data-testid="order-by-field-autocomplete"
				style={{
					width: 310,
					background: selectedTheme.foreground,
					borderRadius: 4,
					borderColor: 'transparent',
				}}
				classes={{
					paper: classes.option,
				}}
				onOpen={async () => {
					if (availableFields.length === 0) {
						await getFields();
					}
				}}
				defaultValue={'updated'}
				disableClearable
				onChange={(option: any, value) => {
					selectedFieldToOrderBy = value;
					dispatch(
						updateDenouncementsField({
							fieldName: 'selectedFieldToOrderBy',
							value: value,
							filterName: selectedFilter,
						})
					);
					// sendSearchQuery();
				}}
				isOptionEqualToValue={(option: any, value) => {
					return option === value;
				}}
				getOptionLabel={(option) => option}
				size="small"
				options={availableFields}
				loading={loadingFields}
				renderInput={(params) => (
					<TextField
						{...params}
						placeholder={t('FilterBar.Ordenar pelo campo') + ':'}
						variant="outlined"
						sx={{
							'& .MuiInputBase-input': {
								color:
									selectedTheme.id === 'dark' && selectedTheme?.textColorHigh,
							},
							'& .MuiFormLabel-root': {
								color:
									selectedTheme.id === 'dark' && selectedTheme?.textColorHigh,
							},
							'& .MuiInputBase-root': {
								'& > fieldset': {
									borderColor:
										selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
								},
								':hover': {
									'& > fieldset': {
										borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
									},
								},
							},
							'.MuiSvgIcon-root ': {
								fill:
									selectedTheme.id === 'dark' && selectedTheme?.textColorMedium,
							},
							background:
								selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
							boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
						}}
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<React.Fragment>
									{loadingFields ? (
										<CircularProgress color="inherit" size={20} />
									) : null}
									{params.InputProps.endAdornment}
								</React.Fragment>
							),
						}}
					/>
				)}
			/>
			<Autocomplete
				data-testid="order-autocomplete"
				style={{
					width: 310,
					background: selectedTheme.foreground,
					borderRadius: 4,
					borderColor: 'transparent',
				}}
				classes={{
					paper: classes.option,
				}}
				onChange={(option: any, value) => {
					selectedOrdination = value?.id;
					dispatch(
						updateDenouncementsField({
							fieldName: 'selectedOrdination',
							value: value?.id,
							filterName: selectedFilter,
						})
					);
					// sendSearchQuery();
				}}
				isOptionEqualToValue={(option: any, value) => {
					return option === value;
				}}
				getOptionLabel={(option) => option.name}
				size="small"
				options={orderOptions}
				defaultValue={orderOptions[1]}
				loading={loadingFields}
				renderInput={(params) => (
					<TextField
						{...params}
						placeholder={t('FilterBar.Ordem') + ':'}
						variant="outlined"
						sx={{
							'& .MuiInputBase-input': {
								color:
									selectedTheme.id === 'dark' && selectedTheme?.textColorHigh,
							},
							'& .MuiFormLabel-root': {
								color:
									selectedTheme.id === 'dark' && selectedTheme?.textColorHigh,
							},
							'& .MuiInputBase-root': {
								'& > fieldset': {
									borderColor:
										selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
								},
								':hover': {
									'& > fieldset': {
										borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
									},
								},
							},
							'.MuiSvgIcon-root ': {
								fill:
									selectedTheme.id === 'dark' && selectedTheme?.textColorMedium,
							},
							background:
								selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
							boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
						}}
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<React.Fragment>
									{loadingFields ? (
										<CircularProgress color="inherit" size={20} />
									) : null}
									{params.InputProps.endAdornment}
								</React.Fragment>
							),
						}}
					/>
				)}
			/>
		</>
	);

	const renderDateFilters = () => (
		<>
			<FormDivider
				name={t('FilterBar.Período')}
				margin="8px 0 6px 0"
				opacity={0.8}
				background={selectedTheme.id === 'dark' && selectedTheme.overlay8dp}
				color={selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : ''}
			/>
			<OfferToolTip title={t('FilterBar.Pressione ENTER para enviar')}>
				<Line
					style={{
						width: '100%',
						justifyContent: 'space-between',
					}}
				>
					<TextField
						id="start-date"
						data-testid="start-date"
						variant="outlined"
						label={t('FilterBar.De')}
						value={selectedStartDate?.slice(0, 10) || 'yyyy-mm-dd'}
						size="small"
						type="date"
						error={selectedFinalDate !== null && selectedStartDate === null}
						onKeyDown={(event) => {
							if (event.keyCode === 13) {
								// sendSearchQuery();
								if (selectedFinalDate) {
									dispatch(
										updateDenouncementsField({
											fieldName: 'selectedStartDate',
											value: selectedStartDate,
											filterName: selectedFilter,
										})
									);
									dispatch(
										updateDenouncementsField({
											fieldName: 'selectedFinalDate',
											value: selectedFinalDate,
											filterName: selectedFilter,
										})
									);
								}
							}
						}}
						onChange={handleChangeStartDate}
						InputLabelProps={{
							shrink: true,
							style: { opacity: 0.7 },
						}}
						sx={{
							width: '48%',
							'& input[type="date"]::-webkit-calendar-picker-indicator': {
								filter: selectedTheme.id === 'dark' ? 'invert(0.7)' : 'none',
							},
							'& .MuiInputBase-input': {
								color:
									selectedTheme.id === 'dark' && selectedTheme?.textColorHigh,
							},
							'& .MuiFormLabel-root': {
								color:
									selectedTheme.id === 'dark' && selectedTheme?.textColorHigh,
							},
							'& .MuiInputBase-root': {
								'& > fieldset': {
									borderColor:
										selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
								},
								':hover': {
									'& > fieldset': {
										borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
									},
								},
							},
							background:
								selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
							boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
						}}
					/>
					<TextField
						id="end-date"
						data-testid="end-date"
						variant="outlined"
						label={t('FilterBar.Até')}
						value={selectedFinalDate?.slice(0, 10) || 'yyyy-mm-dd'}
						size="small"
						type="date"
						error={selectedStartDate !== null && selectedFinalDate === null}
						onKeyDown={(event) => {
							if (event.keyCode === 13) {
								// sendSearchQuery();
								if (selectedStartDate) {
									dispatch(
										updateDenouncementsField({
											fieldName: 'selectedStartDate',
											value: selectedStartDate,
											filterName: selectedFilter,
										})
									);
									dispatch(
										updateDenouncementsField({
											fieldName: 'selectedFinalDate',
											value: selectedFinalDate,
											filterName: selectedFilter,
										})
									);
								}
							}
						}}
						onChange={handleChangeFinalDate}
						InputLabelProps={{
							shrink: true,
							style: { opacity: 0.7 },
						}}
						sx={{
							width: '48%',
							'& input[type="date"]::-webkit-calendar-picker-indicator': {
								filter: selectedTheme.id === 'dark' ? 'invert(0.7)' : 'none',
							},
							'& .MuiInputBase-input': {
								color:
									selectedTheme.id === 'dark' && selectedTheme?.textColorHigh,
							},
							'& .MuiFormLabel-root': {
								color:
									selectedTheme.id === 'dark' && selectedTheme?.textColorHigh,
							},
							'& .MuiInputBase-root': {
								'& > fieldset': {
									borderColor:
										selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
								},
								':hover': {
									'& > fieldset': {
										borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
									},
								},
							},
							background:
								selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
							boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
						}}
					/>
				</Line>
			</OfferToolTip>
		</>
	);

	const filteredOptions = useMemo(
		() =>
			availableUserIDs.filter((option) => option.email.includes(inputValue)),
		[availableUserIDs, inputValue]
	);

	const handleSelectAll = (event) => {
		event.stopPropagation();
		setSelectedUsers(filteredOptions);
	};

	const renderUsersFilters = () => {
		return (
			<>
				<FormDivider
					name={t('FilterBar.Usuários')}
					margin="8px 0 6px 0"
					opacity={0.8}
					background={selectedTheme.id === 'dark' && selectedTheme.overlay8dp}
				/>
				<Autocomplete
					id="users-autocomplete"
					data-testid="users-autocomplete"
					sx={{
						width: '100%',
						background: selectedTheme.foreground,
						borderRadius: '4px',
						borderColor: 'transparent',
					}}
					classes={{
						paper: classes.option,
						option: classes.renderOption,
					}}
					onOpen={async () => {
						if (availableUserIDs.length === 0) {
							await getUsers();
						}
					}}
					onClose={() => {
						setTimeout(() => {
							dispatch(
								updateDenouncementsField({
									fieldName: 'selectedUsers',
									value: selectedUsers,
									filterName: selectedFilter,
								})
							);
						}, 500);
					}}
					onChange={handleChangeSelectedUsers}
					onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
					value={selectedUsers}
					multiple
					limitTags={1}
					clearOnEscape
					isOptionEqualToValue={(option: any, value) => {
						return option.email === value.email;
					}}
					getOptionLabel={(option) => option.email}
					size="small"
					options={availableUserIDs}
					loading={loadingUserIDs}
					renderTags={(value) =>
						value.map((option) => (
							<Chip
								key={option.id}
								size="small"
								label={option?.email}
								sx={{
									color:
										selectedTheme.id === 'dark'
											? selectedTheme?.textColorHigh
											: selectedTheme.foreground,
									border:
										selectedTheme.id === 'dark' ? '1px solid #575757' : 'none',
									backgroundColor: selectedTheme.primaryDark,
								}}
							/>
						))
					}
					renderOption={(props: any, option: any, { selected }) => {
						if (props.id.endsWith('-0')) {
							return (
								<React.Fragment>
									<ListItemButton
										onClick={handleSelectAll}
										sx={{
											background:
												selectedTheme.id === 'dark' &&
												selectedTheme?.overlay3dp,
											'&:hover': {
												background:
													selectedTheme.id === 'dark' &&
													selectedTheme?.foreground,
											},
										}}
									>
										{t('FilterBar.Selecionar todos')}
									</ListItemButton>
									<li {...props} key={option.id}>
										<Checkbox
											checked={selected}
											sx={{
												marginRight: '8px',
												color:
													selectedTheme.id === 'dark'
														? selectedTheme.textColorHigh
														: selectedTheme.primary,
												'&.Mui-checked': {
													color:
														selectedTheme.id === 'dark'
															? selectedTheme.textColorHigh
															: selectedTheme.primary,
												},

												'&:hover': {
													backgroundColodr:
														selectedTheme.id === 'dark' &&
														selectedTheme.primaryLight,
												},
											}}
										/>
										{option.email}
									</li>
								</React.Fragment>
							);
						}
						return (
							<li {...props} key={option.id}>
								<Checkbox
									icon={icon}
									checkedIcon={checkedIcon}
									checked={selected}
									sx={{
										marginRight: '8px',
										color:
											selectedTheme.id === 'dark'
												? selectedTheme.textColorHigh
												: selectedTheme.primary,
										'&.Mui-checked': {
											color:
												selectedTheme.id === 'dark'
													? selectedTheme.textColorHigh
													: selectedTheme.primary,
										},

										'&:hover': {
											backgroundColodr:
												selectedTheme.id === 'dark' &&
												selectedTheme.primaryLight,
										},
									}}
								/>
								{option.email}
							</li>
						);
					}}
					renderInput={(params) => (
						<TextField
							{...params}
							data-testid="users-textField"
							placeholder={
								selectedUsers && selectedUsers.length === 0
									? t('FilterBar.Todos os usuários')
									: undefined
							}
							variant="outlined"
							sx={{
								'& .MuiInputBase-input': {
									color:
										selectedTheme.id === 'dark' && selectedTheme?.textColorHigh,
								},
								'& .MuiInputBase-input::placeholder': {
									color:
										selectedTheme.id === 'dark' && selectedTheme?.textColorHigh,
								},
								'& .MuiInputBase-root': {
									'& > fieldset': {
										borderColor:
											selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
									},
									':hover': {
										'& > fieldset': {
											borderColor:
												selectedTheme.id === 'dark' ? '#fff' : '#000',
										},
									},
								},
								'.MuiSvgIcon-root ': {
									fill:
										selectedTheme.id === 'dark' &&
										selectedTheme?.textColorMedium,
								},
								background:
									selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
								boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
							}}
							InputProps={{
								...params.InputProps,
								type: 'search',
								endAdornment: (
									<React.Fragment>
										{loadingUserIDs ? (
											<CircularProgress color="inherit" size={20} />
										) : null}
										{params.InputProps.endAdornment}
									</React.Fragment>
								),
							}}
						/>
					)}
				/>
			</>
		);
	};
	const renderSaveFilters = () => (
		<>
			<FormDivider
				name={t('FilterBar.Salvar filtros')}
				margin="8px 0"
				opacity={0.8}
				background={selectedTheme.id === 'dark' && selectedTheme.overlay8dp}
				color={selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : ''}
			/>
			<TextField
				variant="outlined"
				data-testid="selectSaveFilters"
				placeholder={t('FilterBar.Salvar grupo de filtros')}
				onChange={(event) => setNameFilter(event.target.value)}
				size="small"
				value={nameFilter}
				sx={{
					width: '100%',
					height: '40px',
					borderRadius: '4px',
					background: selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
					boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
					'& .MuiInputBase-root': {
						'& > fieldset': {
							borderColor: selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
						},
						':hover': {
							'& > fieldset': {
								borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
							},
						},
					},
				}}
				InputProps={{
					disableUnderline: true,
					style: {
						color:
							selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
					},
					endAdornment: (
						<OfferToolTip
							title={t('FilterBar.Salvar grupo')}
							aria-label="inativo"
							enterDelay={700}
							enterNextDelay={700}
							arrow
							TransitionComponent={Zoom}
						>
							<InputAdornment position="end" style={{ marginRight: -12 }}>
								<Divider
									style={{
										height: 28,
										background:
											selectedTheme.id === 'dark'
												? selectedTheme.footerLine
												: '',
									}}
									orientation="vertical"
								/>
								<IconButton
									size="medium"
									data-testid="saveFilters"
									onClick={() => {
										if (nameFilter.trim() !== '') {
											saveDefaultFilters(nameFilter);
											setNameFilter('');
											enqueueSnackbar(t('FilterBar.Grupo salvo com sucesso'), {
												variant: 'success',
											});
										} else {
											enqueueSnackbar(
												t(
													'FilterBar.Dê um nome para seu grupo e tente novamente.'
												),
												{ variant: 'warning' }
											);
										}
									}}
									style={{
										padding: 7,
										borderRadius: '0px 5px 5px 0px ',
										color:
											selectedTheme.id === 'dark'
												? selectedTheme.textColorHigh
												: '',
									}}
									sx={{
										'&:hover': {
											backgroundColor:
												selectedTheme.id === 'dark' &&
												selectedTheme.primaryLight,
										},
									}}
								>
									<Save />
								</IconButton>
							</InputAdornment>
						</OfferToolTip>
					),
				}}
			/>

			<Autocomplete
				id="selectFilter"
				data-testid="selectFilter"
				style={{
					width: 310,
					background:
						selectedTheme.id === 'dark'
							? selectedTheme.overlay3dp
							: selectedTheme.foreground,
					borderRadius: 4,
					borderColor: 'transparent',
					boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
				}}
				sx={{
					'& .MuiSvgIcon-root': {
						color:
							selectedTheme.id === 'dark' ? selectedTheme.textColorMedium : '',
					},
					'& .MuiInputBase-root': {
						'& > fieldset': {
							borderColor: selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
						},
						':hover': {
							'& > fieldset': {
								borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
							},
						},
					},
				}}
				options={Object.keys(filtersRedux['denouncements'])}
				onChange={(event, newValue) => {
					if (newValue === 'defaultFilters') {
						handleClearFilters();
					} else if (newValue) {
						setFilterState(newValue);
						setSelectedFilter(newValue);
					}
				}}
				defaultValue={''}
				disableClearable
				autoHighlight
				autoComplete
				autoSelect
				selectOnFocus
				getOptionLabel={(option: any) =>
					option === 'defaultFilters' ? '' : option
				}
				renderOption={(option: any) => (
					<Line
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							alignItems: 'center',
						}}
					>
						<>
							{option === 'defaultFilters' ? t('FilterBar.Sem filtro') : option}
						</>
						{option !== 'defaultFilters' ? (
							<OfferToolTip
								title={t('FilterBar.Apagar esse grupo')}
								aria-label="inativo"
								enterDelay={700}
								enterNextDelay={700}
								arrow
								TransitionComponent={Zoom}
							>
								<IconButton
									aria-label="Deletfilter"
									data-testid="Deletfilter"
									onClick={(event) => {
										handleClearFilters();
										dispatch(
											removeSpecificFilter({
												filterType: 'denouncements',
												filterName: option,
											})
										);
										event.stopPropagation();
									}}
									size="small"
									style={{
										color:
											selectedTheme.id === 'dark'
												? selectedTheme.textColorMedium
												: selectedTheme.primary,
									}}
									sx={{
										'&:hover': {
											backgroundColor:
												selectedTheme.id === 'dark' &&
												selectedTheme.primaryLight,
										},
									}}
								>
									<CloseRounded />
								</IconButton>
							</OfferToolTip>
						) : null}
					</Line>
				)}
				renderInput={(params: any) => (
					<TextField
						{...params}
						data-testid="selectFilter-textField"
						placeholder={t('FilterBar.Carregar grupo de filtros')}
						variant="outlined"
						style={{ boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)' }}
						InputProps={{
							style: {
								height: '40px',
								padding: '2px',
								paddingLeft: '10px',
								color:
									selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
							},
							...params.InputProps,
						}}
					/>
				)}
			/>
		</>
	);

	const renderClearFiltersButton = () => (
		<>
			<FormDivider name={''} margin="8px 0 3px 0" opacity={0.8} />
			<Line
				style={{
					width: '100%',
					justifyContent: 'flex-end',
				}}
			>
				{loading ? (
					<div style={{ marginRight: 25, marginTop: -5 }}>
						<LoadingDots width={55} />
					</div>
				) : (
					<Button
						variant="text"
						data-testid="clear-filters-button"
						size="small"
						onClick={handleClearFilters}
						sx={{
							marginTop: '-10px',
							color:
								selectedTheme.id === 'dark' && selectedTheme?.textColorHigh,
							'&:hover': {
								backgroundColor:
									selectedTheme.id === 'dark' && selectedTheme.primaryLight,
							},
						}}
					>
						{t('FilterBar.Limpar Filtros')}
					</Button>
				)}
			</Line>
		</>
	);

	return (
		<>
			<Slide
				in={isFilterBarOpen ? true : false}
				direction="right"
				timeout={500}
			>
				<Column
					onBlur={handleCloseOnBlur}
					onMouseOver={() => setMouseOver(true)}
					onMouseLeave={() => setMouseOver(false)}
					style={{
						width: 360,
						transition: 'transform 500ms cubic-bezier(0, 0, 0.2, 1) 0ms',
						alignItems: 'flex-start',
						justifyContent: 'flex-start',
						rowGap: 10,
						padding: '10px 25px',
						left:
							vertMenuState.menuState === false ? drawerWidth : drawerWidthOpen,
						top: 50,
						borderRadius: '0 6px 6px 0',
						border: '1px solid',
						borderColor:
							selectedTheme.id === 'dark'
								? selectedTheme.foreground
								: '#e9e9e9',
						background:
							selectedTheme.id === 'dark'
								? '#303030'
								: selectedTheme.background,
						filter: 'brightness(1.03)',
						boxShadow: 'rgba(0, 0, 0, 0.2) 3px -2px 9px 1px',
						position: 'absolute',
						zIndex: 19,
					}}
				>
					{renderFilterBarHeader()}
					{renderPublicationFilters()}
					{renderDenouncementFilters()}
					{renderPlatformFilters()}
					{renderUsersFilters()}
					{renderDateFilters()}
					{renderResultsFilters()}
					{renderSaveFilters()}
					{renderClearFiltersButton()}
				</Column>
			</Slide>
			<RadialMenu menuData={menuData} loading={loading} />
		</>
	);
};

export default DenouncementsFilterBar;
